<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="d-flex align-center pr-4"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiClipboardMinusOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-2">
              <p class="text-xs mb-0">
                {{ $t('ReadyToBorrow') }}
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ getStaffOverview.available }}
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiClipboardPlusOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-2">
              <p class="text-xs mb-0">
                {{ $t('BeingBorrowed') }}
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ getStaffOverview.borrowed }}
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCalendarClock }}
              </v-icon>
            </v-avatar>

            <div class="ms-2">
              <p class="text-xs mb-0">
                {{ $t('ItemsReturnToday') }}
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ getStaffOverview.return_today }}
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              block
              small
              :to="{name:'sharebox-create'}"
            >
              {{ $t('CreateNewItem') }}
            </v-btn>
            <v-btn
              color="warning"
              block
              class="mt-2"
              small
              :to="{name:'sharebox-return'}"
            >
              {{ $t('ReturnItem') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="mt-4"
    >
      <v-card-text>
        <v-btn
          color="primary"
          block
          :to="{name:'staff-sharebox'}"
          small
          outlined
          exact
        >
          {{ $t('BorrowedItems') }}
        </v-btn>
        <v-btn
          color="warning"
          block
          class="mt-2"
          :to="{name:'staff-sharebox-can-borrow'}"
          small
          outlined
          exact
        >
          {{ $t('AvailableItems') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <router-view ref="childView" />
    <v-dialog
      v-model="showCreateDialog"
      persistent
    >
      <v-form
        ref="createItemForm"
        @submit.prevent="handleSubmit"
      >
        <v-card>
          <v-card-text v-if="getCreateItemData.full_name">
            <p>
              <strong>{{ $t('Owner') }}:</strong> <br />
              {{ getCreateItemData.full_name }}
            </p>
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="createItemForm.title"
              dense
              outlined
              :label="$t('Name')"
              :placeholder="$t('Name')"
              hide-details="auto"
              class="mb-4"
              validate-on-blur
              :rules="[validators.required]"
            ></v-text-field>
            <DatePicker
              v-model="createItemForm.returnDate"
              :label="$t('CanBorrowTo')"
              :rules="[validators.required]"
              :readonly="isEdit"
            />
            <v-textarea
              v-model="createItemForm.description"
              dense
              outlined
              :label="$t('Description')"
              :placeholder="$t('Description')"
              hide-details="auto"
              class="mt-4"
            >
            </v-textarea>
          </v-card-text>
          <v-card-subtitle>Gallery</v-card-subtitle>

          <v-card-text
            v-if="errorsGallery && !imgData.length"
            class="error--text text-caption"
          >
            Gallery is required
          </v-card-text>
          <v-card-text>
            <v-card
              v-for="(img,index) in imgData"
              :key="index"
              class="mt-4"
              outlined
            >
              <v-img :src="img.imageUrl" />
              <v-btn
                absolute
                top
                right
                fab
                x-small
                color="error"
                @click="handleRemoveImg(index)"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-card>

            <v-btn
              :disabled="isHiddenAdd"
              block
              color="primary"
              outlined
              class="mt-4"
              @click="$refs.inputImg.click();"
            >
              <v-icon left>
                {{ icons.mdiImagePlus }}
              </v-icon>
              {{ $t('Add') }}
            </v-btn>
            <input
              ref="inputImg"
              class="d-none"
              type="file"
              accept="image/*"
              multiple
              @input="onUpdateImageItems"
            />
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  block
                  type="submit"
                  :disabled="loadingCreate"
                >
                  <v-progress-circular
                    v-if="loadingCreate"
                    indeterminate
                    color="white"
                    size="20"
                  ></v-progress-circular>
                  <span v-else>
                    {{ $t('Save') }}
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  color="warning"
                  block
                  outlined
                  @click="showCreateDialog = false"
                >
                  {{ $t('Back') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="showQR">
      <v-card>
        <v-card-text
          class="section-to-print"
        >
          <vue-qr
            :text="createItemSuccess.qr"
            :size="250"
          ></vue-qr>
        </v-card-text>
        <v-card-text
          class="text-center section-to-print"
        >
          <p>
            <span class="text-lg">
              <strong>{{ createItemSuccess.title }} - {{ createItemSuccess.id_item }}</strong>
            </span><br />
            <strong>{{ $t('Owner') }}: </strong>
            {{ createItemSuccess.ownerName }}
          </p>
        </v-card-text>
        <v-card-text>
          <v-btn
            color="primary"
            block
            class=""
            @click="handlePrint"
          >
            {{ $t('Print') }}
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-btn
            color="warning"
            block
            outlined
            @click="showQR = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import axios from '@/plugins/axios'
import { required } from '@core/utils/validation'
import { mdiCalendarClock, mdiClipboardMinusOutline, mdiClipboardPlusOutline, mdiClose, mdiImagePlus } from '@mdi/js'
import VueQr from 'vue-qr'
import { mapActions, mapGetters, mapMutations } from 'vuex'

const MAX_IMAGE = 4
export default {
  components: { DatePicker, VueQr },
  data() {
    return {
      showCreateDialog: false,
      showQR: false,
      createItemForm: {
        returnDate: '',
        title: '',
        description: '',
      },
      icons: {
        mdiClipboardPlusOutline,
        mdiClipboardMinusOutline,
        mdiCalendarClock,
        mdiClose,
        mdiImagePlus,
      },
      imgData: [],
      validators: {
        required,
      },

      createItemSuccess: {
        qr: '',
        title: '',
        ownerName: '',
        id_item: '',
      },
      errorsGallery: false,
      loadingCreate: false,
      isEdit: false,
    }
  },
  computed: {
    ...mapGetters('sharebox', ['getStaffOverview', 'getQrData', 'getCreateItemData']),
    isHiddenAdd() {
      return this.imgData.length >= MAX_IMAGE
    },
    id() {
      return this.$route.params.id
    },
  },
  watch: {
    // imgData(newValue) {
    //   console.log('newValue', newValue)
    // },
    async id(value) {
      if (value) {
        await this.showEdit()
      }
    },
    showCreateDialog(newValue) {
      if (!newValue) {
        this.setCreateItemData({})
        this.$set(this, 'createItemForm', {
          returnDate: '',
          title: '',
          description: '',
        })
        this.$set(this, 'imgData', [])
        if (this.id) {
          this.$router.push({ name: 'staff-sharebox-can-borrow' })
        }
      }
    },
    showQR(newValue) {
      if (!newValue) {
        this.$set(this, 'createItemSuccess', {
          qr: '',
          title: '',
          ownerName: '',
        })
      }
    },
  },
  beforeDestroy() {
    this.setQrData(null)
  },
  created() {
    if (this.getQrData) {
      this.fetchUserInfoForCreate().then(() => {
        this.showCreateDialog = true
      }).catch(error => {
        this.$store.commit('snackbar/show', {
          message: error?.message || 'Somethings wrong',
          type: 'error',
        })
      })
    }
    this.fetchStaffOverview()
    if (this.id) {
      this.showEdit()
    }
  },
  methods: {
    ...mapMutations('sharebox', ['setCreateItemData', 'setQrData']),
    ...mapActions('sharebox', ['fetchStaffOverview', 'fetchUserInfoForCreate', 'createItem', 'getItemInfo', 'updateItem']),
    async showEdit() {
      await this.getItemInfo(this.id).then(async res => {
        this.showCreateDialog = true
        this.isEdit = true
        this.$set(this, 'createItemForm', {
          id: res.data.id_item,
          title: res.data.title,
          description: res.data.description,
          returnDate: res.data.can_borrow_to,
          ownerName: res.data.owner.full_name,
        })
        await this.loadImages(res.data.gallery)
      })
    },
    async loadImages(images) {
      function readImage(url) {
        const result = axios.get(url, {
          responseType: 'arraybuffer',
        })
          .then(response => ({
            imageUrl: `data:image/jpeg;base64, ${Buffer.from(response.data, 'binary').toString('base64')}`,
          }))

        return result
      }
      const results = []
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of images) {
        results.push(await readImage(item.url))
      }
      this.$set(this, 'imgData', [...this.imgData, ...results])
    },

    async onUpdateImageItems(e) {
      const { files } = e.target
      if (files.length < 1) return

      // setLoadingImg(true)
      function readImage(file) {
        return new Promise(resolve => {
          const reader = new FileReader()
          reader.fileName = file.name
          reader.onloadend = readerEvt => {
            const base64result = reader.result.split(',')[1]
            resolve({
              imageUrl: reader.result,
              enc: base64result,
              fileName: readerEvt.target.fileName,
            })
          }

          if (file) {
            reader.readAsDataURL(file)
          }
        })
      }
      let promiseList = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (!file.type.match('image') || i + this.imgData.length >= MAX_IMAGE) {
          // eslint-disable-next-line no-continue
          continue
        }
        promiseList = [...promiseList, readImage(file)]
      }
      this.errorsGallery = false

      const result = await Promise.all(promiseList)

      // setLoadingImg(false)
      this.$set(this, 'imgData', [...this.imgData, ...result])
    },
    handleRemoveImg(index) {
      const cloneImgData = [...this.imgData]
      cloneImgData.splice(index, 1)
      this.$set(this, 'imgData', cloneImgData)
    },
    handleSubmit() {
      const isValidForm = this.$refs.createItemForm.validate()

      if (!isValidForm) {
        this.$store.commit('snackbar/show', {
          message: this.$t('RequiredFillData'),
          type: 'error',
        })

        return
      }

      if (!this.imgData?.length) {
        this.errorsGallery = true

        return
      }

      this.errorsGallery = false

      let body = {}
      let method = ''
      if (!this.isEdit) {
        body = {
          ...this.createItemForm,
          ...this.getCreateItemData,
          imgData: this.imgData.map(item => item.imageUrl),
        }
        method = 'createItem'
      } else {
        body = { ...this.createItemForm, imgData: this.imgData.map(item => item.imageUrl) }
        console.log(body)
        method = 'updateItem'
      }

      this.loadingCreate = true
      this[method](body).then(data => {
        this.$set(this, 'createItemSuccess', {
          qr: JSON.stringify({
            id_item: data.id_item,
            id_owner: data.id_owner,
          }),
          title: data.title,
          id_item: data.id_item,
          ownerName: this.createItemForm.ownerName || this.getCreateItemData.full_name,
        })

        this.showCreateDialog = false
        this.showQR = true
      }).finally(() => {
        this.loadingCreate = false
        this.$refs.childView.handleGetData()
      })
    },
    handlePrint() {
      try {
        // Print for Safari browser
        document.execCommand('print', false, null)
      } catch {
        window.print()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
  border: 1px solid #ccc;
  display: block !important;
  margin: 0 auto;
}
.section-to-print img {
  width: 100%;
}

@media print {
  body * {
    visibility: hidden !important;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
  }
}
</style>
